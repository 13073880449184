.num-list {
	position: relative;
	@include flex-grid-row;
	&__item {
		@include flex-grid-column(100%);
		margin-bottom: 20px;
		color: $black;
		position: relative;
		&:before {
			position: absolute;
			content: attr(data-num);
			font-size: 193px;
			font-weight: 900;
			letter-spacing: 9.7px;
			color: rgba(43, 42, 47, 0.1);
			bottom: 0;
			left: 0;
			line-height: .7;
		}
	}
	&__title {
		text-align: center;
		text-transform: uppercase;
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 1px;
		margin-bottom: 1rem;
	}
	&__text {
		text-align: center;
		font-size: 14px;
		letter-spacing: 0.7px;
	}
	
	@include breakpoint (medium) {
		&__item {
			@include flex-grid-column(50%)
		}
	}
	@include breakpoint (large) {
		&__item {
			@include flex-grid-column(25%)
		}
	}
	.tabs-content & {
		margin-top: 20px;
	}
	.tabs-content &__item {
		height: 130px;
		padding-left: 50px;
		&:before {
			left: 0;
			font-size: 120px;
			top: -20px;
			bottom: auto;
		}
	}
	.tabs-content &__title {
		text-align: left;
		line-height: 1.2;
		font-size: 18px;
	}
}