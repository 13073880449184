.footer {
	padding: 30px 0;
	background-color: $black;
	color: $white;
	font-size: 14px;
	letter-spacing: 0.7px;
	&__text {
		opacity: 0.5;
		color: rgba(255, 255, 255, 0.5);
	}
	&__address {
		text-align: center;
	}
	&__phone {
		font-size: 20px;
		letter-spacing: 1px;
		margin-bottom: 1rem;
	}
}