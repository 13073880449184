.page-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&__body {
		flex: 1 1 auto;
	}

	&__footer {
		
	}
}