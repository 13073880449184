.responses {
	&__slider {
		margin-bottom: 35px;
	}
	&__content {
		max-width: 540px;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__content-title {
		text-align: center;
		font-size: 32px;
		line-height: 1.19;
		text-align: center;
		color: #26272d;
		margin-bottom: 51px;
	}
	&__content-text {
		font-size: 20px;
		line-height: 1.4;
		text-align: center;
		color: #8c8f94;
		margin-bottom: 31px;
	}
	&__content-rating {
		margin-bottom: 61px;
	}
	&__content-name {
		font-size: 14px;
		text-transform: uppercase;
		font-weight: bold;
		line-height: 1.57;
		letter-spacing: 1.4px;
		text-align: center;
		color: #26272d;
	}
}

.slider-response {
	&__item {
		
	}
	&__hide {
		display: none;
	}
	&__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__ava {
		margin: 0 auto 40px;
		width: 90px;
		height: 90px;
		margin-top: 20px;
		border-radius: 50%;
		background-color: #26292c;
		background: center center no-repeat / contain;
		opacity: .3;
	}
	
	.slick-center &__ava {
		box-shadow: 25.2px 31.1px 40px 0 rgba(92, 95, 101, 0.28);
		width: 130px;
		height: 130px;
		margin-top: 0;
		opacity: 1;
	}
}

.stars {
	background: url(/assets/img/star.svg);
	height: 13px;
	width: 66px;
	transform: scale(2);
}

.slick-arrow {
	cursor: pointer;
	border: 2px solid #26292c;
	opacity: .2;
	width: 25px;
	height: 25px;
	transform: rotate(-45deg);
	top: 50%;
	position: absolute;
	margin-top: -32px;
	&:hover {
		opacity: 1;
	}
}

.slick-next {
	border-left: none;
	border-top: none;
	right: -30px;
}

.slick-prev {
	border-right: none;
	border-bottom: none;
	left: -30px;
}