.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	&__input {
		display: block;
		font-size: 1rem;
		letter-spacing: 0.8px;
		text-align: center;
		color: #828282;
		height: 44px;
		border: solid 1px #cacaca;
		width: 100%;
		max-width: 370px;
		margin-bottom: 1rem;
	}
}