.product {
	
	&__body {
		padding: 105px 0;
	}
	&__image {
		margin-left: -1000px;
		height: 100%;
		background-position: top right 0;
		background-size: auto 100%;
	}
	&__content {

	}
	&__title {

	}
	&__subtitle {
		margin-bottom: 1rem;
		font-weight: bold;
		font-size: 20px;
	}
	&__brief {
		margin-bottom: 1rem;
		font-size: 20px;
	}
	&__model {

	}
	&__list {
		margin-bottom: 77px;
	}
	&__list-item {
		display: flex;
		letter-spacing: .8px;
		line-height: 23px;
	}
	&__left {
		flex: 0 0 50%;
	}
	&__right {
		flex: 0 0 50%;
	}
	&__price {

	}
	&__button {

	}
}