.scales {
	display: flex;
	flex-direction: column;
	align-items: center;
	&__title {

	}
	&__list {
		align-self: stretch;
		margin-bottom: 27px;
	}
	&__button {

	}
}