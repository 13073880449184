.order {
	display: flex;
	flex-direction: column;
	align-items: center;
	&__title {

	}
	&__brief {
		font-size: 20px;
		letter-spacing: 1px;
		text-align: center;
		color: #2b2a2f;
		margin-bottom: 38px;
		p {
			margin-bottom: 0;
		}
	}
	&__form {
		align-self: stretch;
	}
}