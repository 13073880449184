@mixin pseudo($content: '', $display: block, $pos: absolute){
	content: $content;
	display: $display;
	position: $pos;
}
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
	@include pseudo($pos: $position);
	width: 0;
	height: 0;

	@if $round {
		border-radius: 3px;
	}

	@if $direction == down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
		margin-top: 0 - round( $size / 2.5 );
	} 
	@else if $direction == up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
		margin-bottom: 0 - round( $size / 2.5 );
	} 
	@else if $direction == right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
		margin-right: -$size;
	} 
	@else if  $direction == left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
		margin-left: -$size;
	}
}
@mixin list {
	list-style: none;
	padding: 0;
	margin: 0;
}

@mixin card-white {
	background-color: $bg-white-tr;
	transition: all .2s ease-in-out;
	&:hover {
		background-color: $bg-white;
		box-shadow: 0 0 40px rgba(1, 12, 49, 0.6);
	}
}
@mixin unlist($margin: true, $padding: true) {
	@if $margin {
		@if $margin == 'vertical' {
			@include flush-ends;
		}
		@elseif $margin == 'horizontal' {
			@include flush-sides;
		} 
		@else {
			margin: 0;
		}
	}
	@if $padding {
		padding: 0;
	}
	list-style: none;
}

@mixin list ($gutters: false){
	@include unlist;
	display: flex;
	@if $gutters {
		@include margin-offsets ($gutters / 2);
		& > * {
			@include padding-sides ($gutters / 2);
		}
	} 
	@else {
		justify-content: space-between;
	}
}


@mixin border-radius($radius: $global-radius) {
  @if $enable-rounded {
    border-radius: $radius;
  }
}

@mixin border-top-radius($radius) {
  @if $enable-rounded {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
  }
}

@mixin border-right-radius($radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
  }
}

@mixin border-bottom-radius($radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

@mixin border-left-radius($radius) {
  @if $enable-rounded {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
  }
}

@mixin rub {
	&:after{
		padding-left: 0.1em;
		font-size: .9em;
		content: "\f158";
		font-family: FontAwesome;
	}
}


@mixin align($vertical: true, $horizontal: false, $position: relative) {
	@if $position {position: $position;}
	@if $vertical {top: 50%;}
	@if $horizontal {left: 50%;}
	@if $vertical and $horizontal {transform: translateX(-50%) translateY(-50%);} 
	@else if $vertical {transform: translateY(-50%);} 
	@else {transform: translateX(-50%);}
}

@mixin antialias {
	font-phabletoothing: antialiased;
	-webkit-font-phabletoothing: antialiased;
	-moz-osx-font-phabletoothing: grayscale;
}

@mixin hardware($backface: true, $perspective: 1000) {
	@if $backface {backface-visibility: hidden;}
	perspective: $perspective;
}

@mixin pos($pos, $t: false, $r: false, $b: false, $l: false, $z: false, $hardware: true) {
	@if $pos == fixed and $hardware { @include hardware; }
	@if $pos { position: $pos; }
	@if $t { top: $t; }
	@if $r { right: $r; }
	@if $b { bottom: $b; }
	@if $l { left: $l; }
	@if $z { z-index: $z; }
}
@mixin truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin margin-ends($spacing) { margin: { top: $spacing; bottom: $spacing; } }
@mixin margin-sides($spacing) { margin: { left: $spacing; right: $spacing; } }
@mixin margin-offsets($spacing) { margin: { left: -$spacing; right: -$spacing; } }
@mixin margin-auto { margin: { left: auto; right: auto; } }

@mixin padding-sides($spacing) {padding: {left: $spacing; right: $spacing;}}

@mixin flush-ends { margin: { top: 0; bottom: 0; } }
@mixin flush-sides { margin: { left: 0; right: 0; } }

@mixin unselectable {
	-webkit-touch-callout: none;
	user-select: none;
}

@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

@mixin bg-image{
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}


@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
	&::-webkit-scrollbar {
		width:  $size;
		height: $size;
	}
	&::-webkit-scrollbar-thumb {
		background: $foreground-color;
	}
	&::-webkit-scrollbar-track {
		background: $background-color;
	}
}

@mixin absoluteCenter {
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
}

@mixin retina {
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (-moz-min-device-pixel-ratio: 1.5),
	only screen and (-o-min-device-pixel-ratio: 3 / 2),
	only screen and (min-device-pixel-ratio: 1.5),
	only screen and (min-resolution: 1.5dppx) {
		@content;
	}
}

@mixin input-placeholder {
	&.placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
}

