.section {
	max-width: 1800px;
	margin: auto;
	overflow: hidden;
	padding: 100px 0;
	&__title {

	}
	&__body {
		@include flex-grid-row;
	}
	&--benefits {
		padding: 100px 0 80px;
	}
	&--order {
	}
	&--payments {
		background-color: #f4f4f4;
		padding: 100px 0 26px;
	}
	&--scales {
		background-color: #f0f0f0;
		padding: 94px 0 81px;
	}
	&--product2 {
		padding: 0;
		background-color: #dedede;
	}
	&--product1 {
		padding: 0;
		background-color: #bec1c6;
	}
	&--payments {
		background-color: #f4f4f4;
	}
}
