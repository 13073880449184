.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	background-color: $primary-color;
	border: 1px solid $primary-color;
	appearance: none;
	font-style: normal;
	letter-spacing: 0.8px;
	font-size: 1rem;
	color: $white;
	height: 59px;
	padding-left: 70px;
	padding-right: 70px;
	border-radius: 30px;
	outline: none;
	&.sm {
		font-size: 12.5px;
		letter-spacing: .6px;
		height: 46px;
		padding-left: 50px;
		padding-right: 50px;
	}
	&:hover, &:active, &:focus {
		color: $white;
	}
}