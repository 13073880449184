h2 {
	text-transform: uppercase;
	margin-bottom: 56px;
	font-weight: 900;
	font-size: 40px;
	letter-spacing: 2px;
	text-align: center;
}

input {
	font-style: italic;
	text-align: center;
}

.c-red {
	color: $primary-color;
}

.tabs {
	border-bottom: 2px solid $medium-gray;
	margin-bottom: 85px;
}

.tabs-title {
	width: 50%;
	&.is-active {
		box-shadow: 0 2px 0 $primary-color;
	}
	a {
		outline: none;
		font-size: 18px;
		font-weight: bold;
		text-transform: uppercase;
		text-align: center;
	}
}