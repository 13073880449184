.home {
	&__item {
		padding-top: 167px;
		padding-bottom: 119px;
		background-color: #29282d;
		.columns {
			background: right center no-repeat / 70% auto;
		}
	}
	&__title {
		color: $white;
		letter-spacing: 2px;
		font-size: 40px;
		font-weight: bold;
		margin-bottom: 1rem;
	}
	&__subtitle {
		color: $white;
		letter-spacing: .9px;
		font-size: 18px;
		margin-bottom: 97px;
	}
	&__brief {
		color: $white;
		letter-spacing: .8px;
		margin-bottom: 1rem;
	}
	&__price {
		font-weight: bold;
		color: $white;
		margin-bottom: 68px;
		span {
			font-size: 20px;
			color: $primary-color;
		}
	}
	&__button {

	}
	&__slider {

	}
	&__slide {
		
	}
}

.slick-dots {
	position: absolute;
	list-style: none;
	margin: 0;
	padding: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	bottom: 50px;
	margin: 0 -5px;
	li {
		padding: 0 5px;
		display: block;
		line-height: 1;
		button {
			cursor: pointer;
			text-indent: -10000px;
			border-radius: 50%;
			width: 11px;
			height: 11px;
			border: 2px solid transparent;
			opacity: 0.5;
			background-color: #ffffff;
		}
		&.slick-active {
			button {
				background-color: $primary-color;
				border-color: $primary-color;
				opacity: 1;
			}
		}
	}

}